<template>
  <q-card class="card text-center" flat>
    <q-card-section>
      <img src="../assets/logo.svg"/>
      <h4>Verification</h4>
    </q-card-section>
    <q-card-section>
      <div v-if="!sentEmail && !sentMobile">
        <h5>
          In order to use the portal your account must be verified first.
        </h5>
        <div class="q-mb-sm">
          <q-btn v-if="hasEmail" color="primary" @click="verifyEmail">Send Verification Email</q-btn>
        </div>
        <div>
          <q-btn v-if="hasMobile" color="primary" @click="verifyMobile">Send Mobile Verification Code</q-btn>
        </div>
      </div>
      <h5 v-if="sentEmail">
        We have sent an email verification to {{ user.email }}. Click the link in the email to complete your account
        activation process.
      </h5>

      <div v-if="sentMobile">
        <p>Please enter the code below:</p>
        <q-input label="Code" v-model="code"></q-input>
        <q-btn color="primary" @click="submitCode" class="q-mt-sm">Submit Code</q-btn>
      </div>
      <br>
      <br>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'verify-email',
  data () {
    return {
      sentEmail: false,
      sentMobile: false,
      mobile: null,
      code: ''
    }
  },
  mounted () {
    this.$q.loading.hide()
  },
  methods: {
    async verifyEmail () {
      await this.$dispatch({
        action: 'customer/sendVerificationEmail',
        params: this.user.email,
        message: 'Sending Verification email to ' + this.user.email,
        successMessage: 'We have sent an email verification to ' + this.user.email + '. Click the link in the email to complete your account activation process.'
      })

      this.sentEmail = true
    },
    async verifyMobile () {
      await this.$dispatch({
        action: 'customer/sendPrimaryMobileVerification',
        message: 'Sending Verification code to ' + this.user.mobile,
        successMessage: 'Verification Code was sent.'
      })

      this.sentMobile = true
    },
    async submitCode () {
      await this.$dispatch({
        action: 'customer/verifyPrimaryMobile',
        params: { code: this.code },
        message: 'Verifying...',
        successMessage: 'Verification successful.'
      })

      await this.$store.dispatch('users/destroyCurrentUser')

      this.$router.push({ name: 'dashboard' })
    }
  },
  computed: {
    user () {
      return this.$store.getters['users/currentUser'] ? this.$store.getters['users/currentUser'].params : {}
    },
    hasEmail () {
      return !!this.user.email
    },
    hasMobile () {
      return !!this.user.mobile
    }
  }
}
</script>

<style scoped>

</style>
